import React from 'react';
import { Carousel } from 'react-bootstrap';

import img1 from '../assets/images/projects/IMG_1.jpg';
import img2 from '../assets/images/projects/IMG_2.jpg';
import img3 from '../assets/images/projects/IMG_3.jpg';
import img4 from '../assets/images/projects/IMG_6.jpeg';
import img5 from '../assets/images/projects/IMG_7.jpg';
import img6 from '../assets/images/projects/IMG_8.jpg';

export default class ProjectsSectionCarousel extends React.Component {
    render() {
        return(
            <Carousel
                indicators={false}
                keyboard={false}
                slide={true}
                fade={true}
            >
                <Carousel.Item>
                    <img src={img3} alt="Paysage d'une montage avec la mer" className="grid-2-12 small-grid-1-13" ></img>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img2} alt="DJ regardant l'objectif" className="grid-2-12 small-grid-1-13" ></img>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img1} alt="Des bouées dans l'eau" className="grid-2-12 small-grid-1-13" ></img>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img4} alt="Deux filles sur un bateau qui sourient" className="grid-2-12 small-grid-1-13" ></img>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img5} alt="Des meubles extérieurs avec des ballons" className="grid-2-12 small-grid-1-13" ></img>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img6} alt="Un groupe de filles sur un bateau" className="grid-2-12 small-grid-1-13" ></img>
                </Carousel.Item>
            </Carousel>
        );
    }
}