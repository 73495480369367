import React from "react"
import Header from '../components/header';
import '../styles/projects.scss';
import TitleProjects from "../components/title-projects";
import SeparatorV from "../components/separator-v";
import ProjectsSection from "../components/projects-section";

import { ParallaxProvider } from 'react-scroll-parallax';
import { Parallax } from 'react-scroll-parallax';

import { injectIntl } from "gatsby-plugin-intl";
import Language from "../components/language";
import Contact from '../components/contact';

const ProjectsPage = ({ intl }) => {
  return (
    <div className="section-container">
      <div className="landing">
        <Header buttons={[
          { name: intl.formatMessage({ id: 'meta.buttons.home' }), link: '/' }, 
          { name: intl.formatMessage({ id: 'meta.buttons.talent' }), link: '/talent' },
          { name: intl.formatMessage({ id: 'meta.buttons.brand' }), link: '/brand' }]}>
        </Header>
        <TitleProjects intl={intl}></TitleProjects>
      </div>
      
      <div className="content">
        <SeparatorV></SeparatorV>
        <div className="margin-clear"></div>
        <ProjectsSection Parallax={Parallax} ParallaxProvider={ParallaxProvider} intl={intl}></ProjectsSection>
      </div>
      
      <Contact></Contact>
      <div className="language">
        <Language></Language>
      </div>
    </div>
  )
}
 
export default injectIntl(ProjectsPage);