import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';

export default class VideoComponent extends React.Component {
    playVideo = () => { 
        const videoContainer = document.getElementById(this.props.IdVideo);
        const videoPlayer = videoContainer.childNodes[0];
        const videoButton = videoContainer.childNodes[1];

        if(videoPlayer.paused) {
            videoPlayer.play();
            videoButton.style.visibility = 'hidden'; 
        } else {
            videoPlayer.pause();
            videoButton.style.visibility = 'visible';
        }
    };

    setPlayButtonVisible = () => {
        const videoContainer = document.getElementById(this.props.IdVideo);
        const videoButton = videoContainer.childNodes[1];
        
        if(videoButton.style.visibility !== 'visible')
            videoButton.style.visibility = 'visible';
    }

    render() {
        const props = this.props;
        const buttonStyle = {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
        };

        return(
            <div id={this.props.IdVideo} className="videoContainer" style={{position: "relative"}}>
                { this.props.AutoPlay && 
                    <VisibilitySensor onChange={this.playVideo}>
                        <video className={props.ClassNameVideo} onClick={this.playVideo} onEnded={this.setPlayButtonVisible} muted>
                            <source src={props.UrlVideo} type="video/mp4" />
                            {props.NotFound}
                        </video>
                    </VisibilitySensor>
                }

                { !this.props.AutoPlay &&
                    <video className={props.ClassNameVideo} onClick={this.playVideo} onEnded={this.setPlayButtonVisible}>
                        <source src={props.UrlVideo} type="video/mp4" />
                        {props.NotFound}
                    </video>
                }
                <button className="videoPlayerButton" style={buttonStyle} onClick={this.playVideo}>
                    <div className="custom-player"></div>
                </button>
            </div>
        )
    }
}