import React, { Component } from 'react';
import { Carousel } from 'react-bootstrap';

import projectsSectionStyles from './talent-section.module.scss';

import vid1 from '../assets/images/projects/VID_1.mp4';
import vid2 from '../assets/images/projects/VID_2.mp4';
import vid3 from '../assets/images/projects/VID_3.mp4';
import vid4 from '../assets/images/projects/VID_4.mp4';
import vid5 from '../assets/images/projects/VID_5.mp4';
import vid6 from '../assets/images/projects/VID_6.mp4';
import vid7 from '../assets/images/projects/VID_7.mp4';
import vid8 from '../assets/images/projects/VID_8.mp4';
import vid9 from '../assets/images/projects/VID_9.mp4';

import wideImg1 from '../assets/images/projects/plt_press_trip.mp4';

import campaign from '../assets/images/projects/campaign.jpeg';

import img1 from '../assets/images/projects/IMG_1.jpg';
import img2 from '../assets/images/projects/IMG_2.jpg';
import img3 from '../assets/images/projects/IMG_3.jpg';
import img4 from '../assets/images/projects/IMG_6.jpeg';
import img5 from '../assets/images/projects/IMG_7.jpg';
import img6 from '../assets/images/projects/IMG_8.jpg';

import cluseXmahouf from '../assets/images/projects/IMG_4.jpeg';
import cluseXmahouf2 from '../assets/images/projects/IMG_5.jpeg';
import cluseXmimiarr from '../assets/images/projects/IMG_9.jpg';
import cluseXmimiarr2 from '../assets/images/projects/IMG_10.jpg';
import cluse1V2 from '../assets/images/projects/cluse-1-v2.jpeg';
import cluse2V2 from '../assets/images/projects/cluse-2-v2.jpeg';
import cluse3V2 from '../assets/images/projects/cluse-3-v2.jpeg';

import sfXmisaki from '../assets/images/projects/IMG_11.jpg';
import sfXmisaki2 from '../assets/images/projects/IMG_12.jpg';
import sfXmisaki3 from '../assets/images/projects/IMG_13.jpg';
import sfXnoholita from '../assets/images/projects/IMG_14.jpeg';
import sfXjenifersey from '../assets/images/projects/IMG_15.jpg';
import sfXocerthn from '../assets/images/projects/IMG_16.jpg';
import sfXsephora from '../assets/images/projects/IMG_17.jpg';
import sfXsephora2 from '../assets/images/projects/IMG_18.jpg';
import sfXsephora3 from '../assets/images/projects/IMG_19.jpg';

import tfXanaelle from '../assets/images/projects/IMG_20.jpg';
import tfXanaelle2 from '../assets/images/projects/IMG_21.jpg';

import boohooXladynela from '../assets/images/projects/IMG_22.jpg';
import boohooXsaskia from '../assets/images/projects/IMG_23.jpg';
import boohooXsaskia2 from '../assets/images/projects/IMG_24.jpg';
import boohooXsaskia3 from '../assets/images/projects/IMG_25.jpg';
import boohooXromane from '../assets/images/projects/IMG_26.jpg';
import boohooXeddie from '../assets/images/projects/IMG_27.jpeg';
import boohooXeddie2 from '../assets/images/projects/IMG_28.jpeg';
import boohooXlouise from '../assets/images/projects/IMG_29.jpg';
import boohooXlouise2 from '../assets/images/projects/IMG_30.jpg';
import boohooXlouise3 from '../assets/images/projects/IMG_31.jpg';
import boohooXsephora from '../assets/images/projects/IMG_32.jpg';
import boohooXsephora2 from '../assets/images/projects/IMG_33.jpg';
import boohooXsephora3 from '../assets/images/projects/IMG_34.jpg';
import boohooV2 from '../assets/images/projects/boohoo-v2.jpeg';

import macXbarbara from '../assets/images/projects/IMG_35.jpg';
import macXbarbara2 from '../assets/images/projects/IMG_36.jpg?nocache=no';
import macXlouiseXmleests from '../assets/images/projects/IMG_37.jpg?nocache=no';
import macXmina from '../assets/images/projects/IMG_38.jpg';

import justfabXsephora from '../assets/images/projects/IMG_39.jpg';
import justfabXsephora2 from '../assets/images/projects/IMG_40.jpg';
import justfabXmimiarr from '../assets/images/projects/IMG_41.jpg';
import justfabXmimiarr2 from '../assets/images/projects/IMG_42.jpg';
import justfabXmimiarr3 from '../assets/images/projects/IMG_43.jpg';
import justfabXaudrey from '../assets/images/projects/IMG_44.jpg';
import justfabXaudrey2 from '../assets/images/projects/IMG_45.jpg';
import justfab1V2 from '../assets/images/projects/justfab-1-v2.jpg';
import justfab2V2 from '../assets/images/projects/justfab-2-v2.jpg';
import justfab3V2 from '../assets/images/projects/justfab-3-v2.jpg';

import loungeunderwearXgaelle from '../assets/images/projects/IMG_46.jpg';
import loungeunderwearXgaelle2 from '../assets/images/projects/IMG_47.jpg';
import loungeunderwearXgaelle3 from '../assets/images/projects/IMG_48.jpg';
import loungeunderwearXoce from '../assets/images/projects/IMG_49.jpg';
import loungeunderwearXoce2 from '../assets/images/projects/IMG_50.jpg';
import loungeunderwearXlouise from '../assets/images/projects/IMG_51.jpg';
import loungeunderwearXlouise2 from '../assets/images/projects/IMG_52.jpg';
import loungeunderwearXlouise3 from '../assets/images/projects/IMG_53.jpg';
import loungeunderwearV2 from '../assets/images/projects/underwear-v2.jpg';

import metropolitanXfahdel from '../assets/images/projects/IMG_54.jpg';

import paulhewittXmimiarr from '../assets/images/projects/IMG_55.jpg';
import paulhewittXmimiarr2 from '../assets/images/projects/IMG_56.jpg';

import nakdXgaelle from '../assets/images/projects/IMG_57.jpg';
import nakdXgaelle2 from '../assets/images/projects/IMG_58.jpg';
import nakdXgaelle3 from '../assets/images/projects/IMG_59.jpg';

import revolveXlady from '../assets/images/projects/IMG_60.jpg';

import SeparatorH from './separator-h';
import ContactForm from './contact-form';
import ProjectsSectionLogo from './projects-section-logo';
import ProjectsSectionCarousel from './projects-section-carousel';
import VideoComponent from './video-component';

export default class ProjectsSection extends Component {    
    render() {
        const props = this.props;
        return (
            <props.ParallaxProvider>
                <div className={projectsSectionStyles.container} style={{ zIndex: 0 }}>
                    {/* LOGO SECTION */}
                    <ProjectsSectionLogo></ProjectsSectionLogo>
                </div>
                <div className="bg-white">
                    <props.Parallax y={['-20px', '20px']}>
                        <div className={'title grid-12 mb-30 title-empty'}>
                            <p className="grid-1-13 big-txt">
                                {/* PRESS TRIP */}
                                {props.intl.formatMessage({ id: 'projects.scrollContent.title1' })}
                                <span className="text-joined">
                                    {/* PRETTY LITTLE THING */}
                                    {props.intl.formatMessage({ id: 'projects.scrollContent.title1.1' })}
                                </span>
                            </p>
                        </div>
                    </props.Parallax>

                    {/* WALLPAPER SECTION */}
                    <props.Parallax y={['-10px', '10px']}>
                        <div className={projectsSectionStyles.wideImage + ' vid-bg'}>
                            <video autoPlay loop muted className="video bigvideo">
                                <source src={wideImg1} type="video/mp4"/>
                                {props.intl.formatMessage({ id: 'meta.videonotfound' })}
                            </video>
                        </div>
                    </props.Parallax>

                
                    <props.Parallax y={['-20px', '20px']}>
                        <div className={'title mt-120 mb-120 small-mb-60 subtitle'}>
                            <SeparatorH></SeparatorH>
                            <p className="grid-1-13 title-calvi">
                                {/* Notre voyage de press avec PRETTY LITTLE THING A CALVI ... */}
                                {props.intl.formatMessage({ id: 'projects.scrollContent.title2' })}
                            </p>
                            <SeparatorH></SeparatorH>
                        </div>
                        <div className="talent-section-module--smallImage--xRIlp">
                            <img src={img3} alt="Paysage d'une montage avec la mer" className="grid-2-12 small-grid-1-13" ></img>
                            <img src={img2} alt="DJ regardant l'objectif" className="grid-2-12 small-grid-1-13" ></img>
                            <img src={img1} alt="Des bouées dans l'eau" className="grid-2-12 small-grid-1-13" ></img>
                            <img src={img4} alt="Deux filles sur un bateau qui sourient" className="grid-2-12 small-grid-1-13" ></img>
                            <img src={img5} alt="Des meubles extérieurs avec des ballons" className="grid-2-12 small-grid-1-13" ></img>
                            <img src={img6} alt="Un groupe de filles sur un bateau" className="grid-2-12 small-grid-1-13" ></img>         
                        </div>
                        {/* 
                            
                        */}
                        <div className="carousel-mob">
                            <ProjectsSectionCarousel></ProjectsSectionCarousel>
                        </div>

                        <div className={'text-bold grid-12 mt-60 mb-30 text-centered margin-top first-margin'}>
                            <p className="grid-3-11">
                                {/* FEATURING */}
                                {props.intl.formatMessage({ id: 'projects.scrollContent.text2.1' })}
                            </p>
                        </div>
                        <div className={'text-bold grid-12 mt-60 mb-30 text-centered margin-top'}>
                            <p className="grid-3-11">
                                {/* @LENAMAHOUF @THEDOLLBEAUTY @MAYADORABLE @CLARAMAR ... */}
                                {props.intl.formatMessage({ id: 'projects.scrollContent.text2' })}
                            </p>
                        </div>
                        <div className="first-video">
                            <div className="first-video_container">
                                <VideoComponent AutoPlay={true} UrlVideo={vid1} IdVideo="VID_1" NotFound={props.intl.formatMessage({ id: 'meta.videonotfound' })}/>
                                <div className="badge first-badge">
                                    {/*#EVENT ... */}
                                    {props.intl.formatMessage({ id: 'projects.scrollContent.text2.2' })}
                                </div>
                            </div>
                        </div>
                    </props.Parallax>
                </div>
                <div className={projectsSectionStyles.container} style={{ zIndex: 0 }}>        
                    {/* CAMPAGNE */}
                    <props.Parallax y={['-10px', '10px']}>
                        <div className="full-img-container">
                            <div className='title title-empty title-empty text-centered'>
                                <p>
                                    <span className="text-joined">
                                        {props.intl.formatMessage({ id: 'projects.scrollContent.campaign' })}
                                    </span>
                                </p>
                            </div>
                            <div className="img-campaign">
                                <img src={campaign} alt="Campaign"/>
                            </div>
                        </div>
                    </props.Parallax>

                    <props.Parallax y={['-10px', '10px']}>
                        <div>
                            <div className={'title grid-12 mt-120 mb-120 project-title'}>
                                <p className="grid-1-13">
                                    {/* CLUSE */}
                                    {props.intl.formatMessage({ id: 'projects.scrollContent.title3' })}
                                </p>
                            </div>
                            {/* LEFT CONTAINER */}
                                <div className={'subprojet-container reverse'}>
                                        <div className={'img-container few-pictures'}>
                                            <img src={cluseXmahouf} alt="Fille au sol qui sourie" className="grid-2-12 small-grid-1-13 img-50" ></img>
                                            <img src={cluseXmahouf2} alt="Fille au sol qui rigole" className="grid-2-12 small-grid-1-13 img-50" ></img>
                                        </div>
                                        <Carousel
                                            indicators={false}
                                            keyboard={false}
                                            slide={true}
                                            fade={true}
                                        >
                                            <Carousel.Item>
                                                <img src={cluseXmahouf} alt="Fille au sol qui sourie" className="grid-2-12 small-grid-1-13" ></img>
                                            </Carousel.Item>
                                            <Carousel.Item>
                                                <img src={cluseXmahouf2} alt="Fille au sol qui rigole" className="grid-2-12 small-grid-1-13" ></img>
                                            </Carousel.Item>
                                        </Carousel>
                                    {/* RIGHT CONTAINER */}
                                    <div className="text">
                                            <p>
                                                {/* CLUSE */}
                                                {props.intl.formatMessage({ id: 'projects.scrollContent.title3' })}
                                            </p>
                                            <p>
                                                {/* X LENA MAHOUF */}
                                                {props.intl.formatMessage({ id: 'projects.scrollContent.title3.1' })}
                                            </p>
                                            <SeparatorH></SeparatorH>
                                            <div className="badge">{props.intl.formatMessage({ id: 'projects.scrollContent.fashion' })}</div>
                                    </div>
                                </div>
                        </div>
                    </props.Parallax>

                    <props.Parallax y={['-10px', '10px']}>
                        <div>
                            <div className={'title grid-12 mt-120 mb-120 mobile-space'}></div>
                            {/* LEFT CONTAINER */}
                                <div className={'subprojet-container pics-right'}>
                                    <div className="text align">
                                        <p>
                                            {/* CLUSE */}
                                            {props.intl.formatMessage({ id: 'projects.scrollContent.title3' })}
                                        </p>
                                        <p>
                                            {/* X LENA MAHOUF */}
                                            {props.intl.formatMessage({ id: 'projects.scrollContent.title3.2' })}
                                        </p>
                                        <SeparatorH></SeparatorH>
                                        <div className="badge">{props.intl.formatMessage({ id: 'projects.scrollContent.fashion' })}</div>
                                    </div>
                                    {/* RIGHT CONTAINER */}
                                    <div className={'img-container few-pictures'}>
                                        <img src={cluseXmimiarr} alt="Fille qui regarde l'objectif vers l'arrière" className="grid-2-12 small-grid-1-13 img-50" ></img>
                                        <img src={cluseXmimiarr2} alt="Main avec une montre au poignet tenant une fleur" className="grid-2-12 small-grid-1-13 img-50" ></img>
                                    </div>
                                    <Carousel
                                        indicators={false}
                                        keyboard={false}
                                        slide={true}
                                        fade={true}
                                    >
                                        <Carousel.Item>
                                            <img src={cluseXmimiarr} alt="Fille qui regarde l'objectif vers l'arrière" className="grid-2-12 small-grid-1-13" ></img>
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <img src={cluseXmimiarr2} alt="Main avec une montre au poignet tenant une fleur" className="grid-2-12 small-grid-1-13" ></img>
                                        </Carousel.Item>
                                    </Carousel>
                                </div>
                        </div>
                    </props.Parallax>

                    <props.Parallax y={['-10px', '10px']}>
                        <div>
                            <div className={'title grid-12 mt-120 mb-120 mobile-space'}></div>
                            {/* ABOVE CONTAINER */}
                                <div className="text align">
                                    <p>
                                        {/* CLUSE V2  */}
                                        {props.intl.formatMessage({ id: 'projects.scrollContent.title3' })}
                                    </p>
                                    <p>
                                        {/* X MANON LAIME */}
                                        {props.intl.formatMessage({ id: 'projects.scrollContent.title3.3' })}
                                    </p>
                                    <SeparatorH></SeparatorH>
                                    <div className="badge">{props.intl.formatMessage({ id: 'projects.scrollContent.fashion' })}</div>
                                </div>
                                <div className={'subprojet-container'}>
                                    {/* BELOW CONTAINER */}
                                    <div className={'img-container pic3 few-pictures'}>
                                        <img src={cluse1V2} alt="Fille en sous-vêtement regardant l'objectif" className="grid-2-12 small-grid-1-13 img-33"></img>
                                        <img src={cluse2V2} alt="Fille en sous-vêtement avec une couronne regardant l'objectif" className="grid-2-12 small-grid-1-13 img-33" ></img>
                                        <img src={cluse3V2} alt="Fille en sous-vêtement regardant l'objectif avec des bougies au premier plan" className="grid-2-12 small-grid-1-13 img-33"></img>
                                    </div>
                                    <Carousel
                                        indicators={false}
                                        keyboard={false}
                                        slide={true}
                                        fade={true}
                                    >
                                        <Carousel.Item>
                                            <img src={cluse1V2} alt="Fille en sous-vêtement regardant l'objectif" className="grid-2-12 small-grid-1-13" ></img>
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <img src={cluse2V2} alt="Fille en sous-vêtement avec une couronne regardant l'objectif" className="grid-2-12 small-grid-1-13" ></img>
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <img src={cluse3V2} alt="Fille en sous-vêtement regardant l'objectif avec des bougies au premier plan" className="grid-2-12 small-grid-1-13" ></img>
                                        </Carousel.Item>
                                    </Carousel>
                                </div>
                        </div>
                    </props.Parallax>

                    <props.Parallax y={['-10px', '10px']}>
                        <div>
                            <div className={'title grid-12 mt-120 mb-120 project-title'}>
                                <p className="grid-1-13">
                                    {/* SAVAGE X FENTY */}
                                    {props.intl.formatMessage({ id: 'projects.scrollContent.title5' })}
                                </p>
                            </div>
                            {/* ABOVE CONTAINER */}
                                <div className="text align">
                                    <p>
                                        {/* SAVAGE X FENTY  */}
                                        {props.intl.formatMessage({ id: 'projects.scrollContent.title5' })}
                                    </p>
                                    <p>
                                        {/* X LALAA MISAKI */}
                                        {props.intl.formatMessage({ id: 'projects.scrollContent.title5.1' })}
                                    </p>
                                    <SeparatorH></SeparatorH>
                                    <div className="badge">{props.intl.formatMessage({ id: 'projects.scrollContent.fashion' })}</div>
                                </div>
                                <div className={'subprojet-container'}>
                                    {/* BELOW CONTAINER */}
                                    <div className={'img-container pic3 few-pictures'}>
                                        <img src={sfXmisaki} alt="Fille en sous-vêtement regardant l'objectif" className="grid-2-12 small-grid-1-13 img-33"></img>
                                        <img src={sfXmisaki2} alt="Fille en sous-vêtement avec une couronne regardant l'objectif" className="grid-2-12 small-grid-1-13 img-33" ></img>
                                        <img src={sfXmisaki3} alt="Fille en sous-vêtement regardant l'objectif avec des bougies au premier plan" className="grid-2-12 small-grid-1-13 img-33"></img>
                                    </div>
                                    <Carousel
                                        indicators={false}
                                        keyboard={false}
                                        slide={true}
                                        fade={true}
                                    >
                                        <Carousel.Item>
                                            <img src={sfXmisaki} alt="Fille en sous-vêtement regardant l'objectif" className="grid-2-12 small-grid-1-13" ></img>
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <img src={sfXmisaki2} alt="Fille en sous-vêtement avec une couronne regardant l'objectif" className="grid-2-12 small-grid-1-13" ></img>
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <img src={sfXmisaki3} alt="Fille en sous-vêtement regardant l'objectif avec des bougies au premier plan" className="grid-2-12 small-grid-1-13" ></img>
                                        </Carousel.Item>
                                    </Carousel>
                                </div>
                        </div>
                    </props.Parallax>

                    <props.Parallax y={['-10px', '10px']}>
                        <div>
                            <div className={'title grid-12 mt-120 mb-120 mobile-space'}></div>
                            {/* LEFT CONTAINER */}
                                <div className={'subprojet-container float-right-pic'}>
                                        <div className="text">
                                                <p>
                                                    {/* SAVAGE X FENTY */}
                                                    {props.intl.formatMessage({ id: 'projects.scrollContent.title5' })}
                                                </p>
                                                <p>
                                                    {/* X NOHOLITA */}
                                                    {props.intl.formatMessage({ id: 'projects.scrollContent.title5.2' })}
                                                </p>
                                                <SeparatorH></SeparatorH>
                                                <div className="badge">{props.intl.formatMessage({ id: 'projects.scrollContent.fashion' })}</div>
                                        </div>
                                    {/* RIGHT CONTAINER */}
                                    <div className={'img-container'}>
                                        <img src={sfXnoholita} alt="Fille en sous-vêtement devant un miroir" className="grid-2-12 small-grid-1-13 img-100" ></img>
                                    </div>
                                </div>
                        </div>
                    </props.Parallax>

                    <props.Parallax y={['-10px', '10px']}>
                        <div>
                            <div className={'title grid-12 mt-120 mb-120 mobile-space'}></div>
                            {/* LEFT CONTAINER */}
                                <div className={'subprojet-container reverse  float-left-pic'}>
                                    <div className={'img-container'}>
                                        <img src={sfXjenifersey} alt="Fille portant un soutient-gorge et un jean" className="grid-2-12 small-grid-1-13 img-100" ></img>
                                    </div>
                                    {/* RIGHT CONTAINER */}
                                    <div className="text">
                                            <p>
                                                {/* SAVAGE X FENTY */}
                                                {props.intl.formatMessage({ id: 'projects.scrollContent.title5' })}
                                            </p>
                                            <p>
                                                {/* X NOHOLITA */}
                                                {props.intl.formatMessage({ id: 'projects.scrollContent.title5.3' })}
                                            </p>
                                            <SeparatorH></SeparatorH>
                                            <div className="badge">{props.intl.formatMessage({ id: 'projects.scrollContent.fashion' })}</div>
                                    </div>
                                </div>
                        </div>
                    </props.Parallax>

                    {/* <props.Parallax y={['-10px', '10px']}>
                        <div>
                            <div className={'title title-empty grid-12 mt-120 mb-120'}></div>
                            
                            {/* RIGHT CONTAINER 
                                <div className={'subprojet-container float-right-pic'}>
                                    <div className="text">
                                            <p>
                                                {/* SAVAGE X FENTY 
                                                {props.intl.formatMessage({ id: 'projects.scrollContent.title5' })}
                                            </p>
                                            <p>
                                                {/* X OCE.RTHN 
                                                {props.intl.formatMessage({ id: 'projects.scrollContent.title5.4' })}
                                            </p>
                                            <SeparatorH></SeparatorH>
                                            <div className="badge">{props.intl.formatMessage({ id: 'projects.scrollContent.fashion' })}</div>
                                    </div>
                                    
                                    {/* LEFT CONTAINER 
                                    <div className={'img-container'}>
                                        <img src={sfXocerthn} alt="Fille en sous-vêtement gris dans un salon" className="grid-2-12 small-grid-1-13 img-100" ></img>
                                    </div>
                                </div>
                        </div>
                    </props.Parallax>*/}

                    <props.Parallax y={['-10px', '10px']}>
                        <div>
                            <div className={'title grid-12 mt-120 mb-120 mobile-space'}></div>
                            {/* ABOVE CONTAINER */}
                                <div className="text align">
                                    <p>
                                        {/* SAVAGE X FENTY  */}
                                        {props.intl.formatMessage({ id: 'projects.scrollContent.title5' })}
                                    </p>
                                    <p>
                                        {/* X SEPHORA KNG */}
                                        {props.intl.formatMessage({ id: 'projects.scrollContent.title5.5' })}
                                    </p>
                                    <SeparatorH></SeparatorH>
                                    <div className="badge">{props.intl.formatMessage({ id: 'projects.scrollContent.fashion' })}</div>
                                </div>
                                <div className={'subprojet-container'}>                                
                                    {/* BELOW CONTAINER */}
                                    <div className={'img-container pic3 few-pictures'}>
                                        <img src={sfXsephora} alt="Fille en maillot de bain une pièce avec un gilet" className="grid-2-12 small-grid-1-13 img-33" ></img>
                                        <img src={sfXsephora2} alt="Fille avec un gilet à capuche" className="grid-2-12 small-grid-1-13 img-33" ></img>
                                        <img src={sfXsephora3} alt="Fille en maillot de bain une pièce à côté d'un sapin" className="grid-2-12 small-grid-1-13 img-33" ></img>
                                    </div>
                                    <Carousel
                                        indicators={false}
                                        keyboard={false}
                                        slide={true}
                                        fade={true}
                                    >
                                        <Carousel.Item>
                                            <img src={sfXsephora} alt="Fille en maillot de bain une pièce avec un gilet" className="grid-2-12 small-grid-1-13" ></img>
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <img src={sfXsephora2} alt="Fille avec un gilet à capuche" className="grid-2-12 small-grid-1-13" ></img>
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <img src={sfXsephora3} alt="Fille en maillot de bain une pièce à côté d'un sapin" className="grid-2-12 small-grid-1-13" ></img>
                                        </Carousel.Item>
                                    </Carousel>
                                </div>
                        </div>
                    </props.Parallax>

                    <props.Parallax y={['-10px', '10px']}>
                        <div>
                            <div className={'title grid-12 mt-120 mb-120 project-title'}>
                                <p className="grid-1-13">
                                    {/* TOO FACED */}
                                    {props.intl.formatMessage({ id: 'projects.scrollContent.title6' })}
                                </p>
                            </div>
                            {/* LEFT CONTAINER */}
                                <div className={'subprojet-container reverse'}>
                                    <div className={'img-container few-pictures'}>
                                        <img src={tfXanaelle} alt="Fille devant un miroir entrain de se maquiller" className="grid-2-12 small-grid-1-13 img-50" ></img>
                                        <img src={tfXanaelle2} alt="Fille devant un miroir entrain de se maquiller" className="grid-2-12 small-grid-1-13 img-50" ></img>
                                    </div>
                                    <Carousel
                                        indicators={false}
                                        keyboard={false}
                                        slide={true}
                                        fade={true}
                                    >
                                        <Carousel.Item>
                                            <img src={tfXanaelle} alt="Fille devant un miroir entrain de se maquiller" className="grid-2-12 small-grid-1-13" ></img>
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <img src={tfXanaelle2} alt="Fille devant un miroir entrain de se maquiller" className="grid-2-12 small-grid-1-13" ></img>
                                        </Carousel.Item>
                                    </Carousel>
                                    {/* RIGHT CONTAINER */}
                                    <div className="text">
                                            <p>
                                                {/* TOO FACED  */}
                                                {props.intl.formatMessage({ id: 'projects.scrollContent.title6' })}
                                            </p>
                                            <p>
                                                {/* X ANAELLE POSTOLLEC */}
                                                {props.intl.formatMessage({ id: 'projects.scrollContent.title6.1' })}
                                            </p>
                                            <SeparatorH></SeparatorH>
                                            <div className="badge">{props.intl.formatMessage({ id: 'projects.scrollContent.beauty' })}</div>
                                    </div>
                                </div>
                        </div>
                    </props.Parallax>

                    <props.Parallax y={['-10px', '10px']}>
                        <div>
                            <div className={'title grid-12 mt-120 mb-120 project-title'}>
                                <p className="grid-1-13">
                                    {/* JBL */}
                                    {props.intl.formatMessage({ id: 'projects.scrollContent.title7' })}
                                </p>
                            </div>
                            {/* ABOVE CONTAINER */}
                                <div className="text text-video">
                                    <p>
                                        {/* JBL  */}
                                        {props.intl.formatMessage({ id: 'projects.scrollContent.title7' })}
                                    </p>
                                    <p>
                                        {/* X ANAELLE POSTOLLEC */}
                                        {props.intl.formatMessage({ id: 'projects.scrollContent.title7.1' })}
                                    </p>
                                    <SeparatorH></SeparatorH>
                                    <div className="badge">{props.intl.formatMessage({ id: 'projects.scrollContent.lifestyle' })}</div>
                                    {/* BELOW CONTAINER */}
                                    <VideoComponent UrlVideo={vid2} IdVideo="VID_2" ClassNameVideo="video" NotFound={props.intl.formatMessage({ id: 'meta.videonotfound' })}/>
                                </div>                        
                        </div>
                    </props.Parallax>

                    <props.Parallax y={['-10px', '10px']}>
                        <div>
                            <div className={'title grid-12 mt-120 mb-120 mobile-space'}></div>
                            {/* ABOVE CONTAINER */}
                            
                                <div className="text align">
                                        <p>
                                            {/* JBL  */}
                                            {props.intl.formatMessage({ id: 'projects.scrollContent.title7' })}
                                        </p>
                                        <p>
                                            {/* X EDDIE CUDI */}
                                            {props.intl.formatMessage({ id: 'projects.scrollContent.title7.2' })}
                                        </p>
                                        <SeparatorH></SeparatorH>
                                        <div className="badge">{props.intl.formatMessage({ id: 'projects.scrollContent.lifestyle' })}</div>
                                </div>
                                
                                {/* BELOW CONTAINER */}
                                <div className={'img-container flex-video'}>
                                    <VideoComponent UrlVideo={vid3} IdVideo="VID_3" ClassNameVideo="video video-33" NotFound={props.intl.formatMessage({ id: 'meta.videonotfound' })}/>
                                    <VideoComponent UrlVideo={vid4} IdVideo="VID_4" ClassNameVideo="video video-33" NotFound={props.intl.formatMessage({ id: 'meta.videonotfound' })}/>
                                    <VideoComponent UrlVideo={vid5} IdVideo="VID_5" ClassNameVideo="video video-33" NotFound={props.intl.formatMessage({ id: 'meta.videonotfound' })}/>
                                </div>
                            
                        </div>
                    </props.Parallax>

                    <props.Parallax y={['-10px', '10px']}>
                        <div>
                            <div className={'title grid-12 mt-120 mb-120 mobile-space'}></div>
                            {/* ABOVE CONTAINER */}
                                <div className="text text-video video-right-bis">
                                    <p>
                                        {/* JBL  */}
                                        {props.intl.formatMessage({ id: 'projects.scrollContent.title7' })}
                                    </p>
                                    <p>
                                        {/* X MAILE AKLN */}
                                        {props.intl.formatMessage({ id: 'projects.scrollContent.title7.3' })}
                                    </p>
                                    <SeparatorH></SeparatorH>
                                    <div className="badge">{props.intl.formatMessage({ id: 'projects.scrollContent.lifestyle' })}</div>
                                    {/* BELOW CONTAINER */}
                                    <VideoComponent UrlVideo={vid6} IdVideo="VID_6" ClassNameVideo="video" NotFound={props.intl.formatMessage({ id: 'meta.videonotfound' })}/>
                                </div>
                        </div>
                    </props.Parallax>

                    <props.Parallax y={['-10px', '10px']}>
                        <div>
                            <div className={'title grid-12 mt-120 mb-120 project-title margin-title'}>
                                <p className="grid-1-13">
                                    {/* BOOHOO */}
                                    {props.intl.formatMessage({ id: 'projects.scrollContent.title8' })}
                                </p>
                            </div>
                            {/* LEFT CONTAINER */}
                                <div className={'subprojet-container reverse float-left-pic'}>
                                    <div className={'img-container'}>
                                        <img src={boohooXladynela} alt="Fille devant un ascenceur avec un sac à main" className="grid-2-12 small-grid-1-13 img-100" ></img>
                                    </div>
                                    {/* RIGHT CONTAINER */}
                                    <div className="text">
                                        <p>
                                            {/* BOOHOO  */}
                                            {props.intl.formatMessage({ id: 'projects.scrollContent.title8' })}
                                        </p>
                                        <p>
                                            {/* X LADY NELA */}
                                            {props.intl.formatMessage({ id: 'projects.scrollContent.title8.1' })}
                                        </p>
                                        <SeparatorH></SeparatorH>
                                        <div className="badge">{props.intl.formatMessage({ id: 'projects.scrollContent.fashion' })}</div>
                                    </div>
                                </div>
                        </div>
                    </props.Parallax>

                    <props.Parallax y={['-10px', '10px']}>
                        <div>
                            <div className={'title grid-12 mt-120 mb-120 mobile-space'}></div>
                            {/* ABOVE CONTAINER */}
                            <div className="text align">
                                <p>
                                    {/* BOOHOO  */}
                                    {props.intl.formatMessage({ id: 'projects.scrollContent.title8' })}
                                </p>
                                <p>
                                    {/* X SASKIA FREITAS */}
                                    {props.intl.formatMessage({ id: 'projects.scrollContent.title8.2' })}
                                </p>
                                <SeparatorH></SeparatorH>
                                <div className="badge">{props.intl.formatMessage({ id: 'projects.scrollContent.fashion' })}</div>
                            </div>
                            <div className={'subprojet-container'}>                                
                                {/* BELOW CONTAINER */}
                                <div className={'img-container pic3 few-pictures'}>
                                    <img src={boohooXsaskia} alt="Fille dans un hall parisien en débardeur rouge" className="grid-2-12 small-grid-1-13 img-33" ></img>
                                    <img src={boohooXsaskia2} alt="Fille avec un bonnet à l'extérieur" className="grid-2-12 small-grid-1-13 img-33" ></img>
                                    <img src={boohooXsaskia3} alt="Fille dans un ascenceur en ensemble noir" className="grid-2-12 small-grid-1-13 img-33" ></img>
                                </div>
                                <Carousel
                                    indicators={false}
                                    keyboard={false}
                                    slide={true}
                                    fade={true}
                                >
                                    <Carousel.Item>
                                        <img src={boohooXsaskia} alt="Fille dans un hall parisien en débardeur rouge" className="grid-2-12 small-grid-1-13" ></img>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img src={boohooXsaskia2} alt="Fille avec un bonnet à l'extérieur" className="grid-2-12 small-grid-1-13" ></img>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img src={boohooXsaskia3} alt="Fille dans un ascenceur en ensemble noir" className="grid-2-12 small-grid-1-13" ></img>
                                    </Carousel.Item>
                                </Carousel>
                            </div>
                        </div>
                    </props.Parallax>

                    <props.Parallax y={['-10px', '10px']}>
                        <div>
                            <div className={'title grid-12 mt-120 mb-120 mobile-space'}></div>
                            {/* ABOVE CONTAINER */}
                            <div className="one-picture">
                                <div className="text text-video">
                                        <p>
                                            {/* BOOHOO  */}
                                            {props.intl.formatMessage({ id: 'projects.scrollContent.title8' })}
                                        </p>
                                        <p>
                                            {/* X ROMANE INNC */}
                                            {props.intl.formatMessage({ id: 'projects.scrollContent.title8.3' })}
                                        </p>
                                        <SeparatorH></SeparatorH>
                                        <div className="badge">{props.intl.formatMessage({ id: 'projects.scrollContent.fashion' })}</div>
                                </div>
                                {/* BELOW CONTAINER */}
                                <div className={'subprojet-container soloImg'}>
                                    <div className={'img-container'}>
                                        <img src={boohooXromane} alt="Fille avec un manteau beige" className="grid-2-12 small-grid-1-13 img-100" ></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </props.Parallax>

                    <props.Parallax y={['-10px', '10px']}>
                        <div>
                            <div className={'title grid-12 mt-120 mb-120 mobile-space'}></div>
                            {/* LEFT CONTAINER */}
                            <div className={'subprojet-container reverse'}>
                                <div className={'img-container few-pictures'}>
                                    <img src={boohooXeddie} alt="Homme en maillot de bain avec des lunettes de soleil" className="grid-2-12 small-grid-1-13 img-50" ></img>
                                    <img src={boohooXeddie2} alt="Homme en chemise avec des lunettes de soleil" className="grid-2-12 small-grid-1-13 img-50" ></img>
                                </div>
                                <Carousel
                                    indicators={false}
                                    keyboard={false}
                                    slide={true}
                                    fade={true}
                                >
                                    <Carousel.Item>
                                        <img src={boohooXeddie} alt="Homme en maillot de bain avec des lunettes de soleil" className="grid-2-12 small-grid-1-13" ></img>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img src={boohooXeddie2} alt="Homme en chemise avec des lunettes de soleil" className="grid-2-12 small-grid-1-13" ></img>
                                    </Carousel.Item>
                                </Carousel>
                                {/* RIGHT CONTAINER */}
                                <div className="text">
                                    <p>
                                        {/* BOOHOO  */}
                                        {props.intl.formatMessage({ id: 'projects.scrollContent.title8' })}
                                    </p>
                                    <p>
                                        {/* X EDDIE CUDI*/}
                                        {props.intl.formatMessage({ id: 'projects.scrollContent.title8.4' })}
                                    </p>
                                    <SeparatorH></SeparatorH>
                                    <div className="badge">{props.intl.formatMessage({ id: 'projects.scrollContent.fashion' })}</div>
                                </div>
                            </div>
                        </div>
                    </props.Parallax>

                    <props.Parallax y={['-10px', '10px']}>
                        <div>
                            <div className={'title grid-12 mt-120 mb-120 mobile-space'}></div>
                            {/* ABOVE CONTAINER */}
                                <div className="text align">
                                    <p>
                                        {/* BOOHOO  */}
                                        {props.intl.formatMessage({ id: 'projects.scrollContent.title8' })}
                                    </p>
                                    <p>
                                        {/* X LOUISE RGT */}
                                        {props.intl.formatMessage({ id: 'projects.scrollContent.title8.5' })}
                                    </p>
                                    <SeparatorH></SeparatorH>
                                    <div className="badge">{props.intl.formatMessage({ id: 'projects.scrollContent.fashion' })}</div>
                                </div>
                                <div className={'subprojet-container'}>                                
                                    {/* BELOW CONTAINER */}
                                    <div className={'img-container pic3 few-pictures'}>
                                        <img src={boohooXlouise} alt="Fille portant un crop top et tenant un sac à main" className="grid-2-12 small-grid-1-13 img-33" ></img>
                                        <img src={boohooXlouise2} alt="Fille en tenue décontractée" className="grid-2-12 small-grid-1-13 img-33" ></img>
                                        <img src={boohooXlouise3} alt="Fille avec une robe noire avec des bottes" className="grid-2-12 small-grid-1-13 img-33" ></img>
                                    </div>
                                    <Carousel
                                    indicators={false}
                                    keyboard={false}
                                    slide={true}
                                    fade={true}
                                >
                                    <Carousel.Item>
                                        <img src={boohooXlouise} alt="Fille portant un crop top et tenant un sac à main" className="grid-2-12 small-grid-1-13" ></img>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img src={boohooXlouise2} alt="Fille en tenue décontractée" className="grid-2-12 small-grid-1-13" ></img>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img src={boohooXlouise3} alt="Fille avec une robe noire avec des bottes" className="grid-2-12 small-grid-1-13" ></img>
                                    </Carousel.Item>
                                </Carousel>
                                </div>
                        </div>
                    </props.Parallax>

                    <props.Parallax y={['-10px', '10px']}>
                        <div>
                            <div className={'title grid-12 mt-120 mb-120 mobile-space'}></div>
                            {/* ABOVE CONTAINER */}
                                <div className="text align">
                                    <p>
                                        {/* BOOHOO  */}
                                        {props.intl.formatMessage({ id: 'projects.scrollContent.title8' })}
                                    </p>
                                    <p>
                                        {/* X SEPHORA KNG */}
                                        {props.intl.formatMessage({ id: 'projects.scrollContent.title8.6' })}
                                    </p>
                                    <SeparatorH></SeparatorH>
                                    <div className="badge">{props.intl.formatMessage({ id: 'projects.scrollContent.fashion' })}</div>
                                </div>
                                <div className={'subprojet-container'}>                                
                                    {/* BELOW CONTAINER */}
                                    <div className={'img-container pic3 few-pictures'}>
                                        <img src={boohooXsephora} alt="Fille se tenant la queue de cheval" className="grid-2-12 small-grid-1-13 img-33" ></img>
                                        <img src={boohooXsephora2} alt="Fille en tenue décontracé au milieu d'un stade" className="grid-2-12 small-grid-1-13 img-33" ></img>
                                        <img src={boohooXsephora3} alt="Fille dans un ascenceur avec un trench camel" className="grid-2-12 small-grid-1-13 img-33" ></img>
                                    </div>
                                    <Carousel
                                        indicators={false}
                                        keyboard={false}
                                        slide={true}
                                        fade={true}
                                    >
                                        <Carousel.Item>
                                            <img src={boohooXsephora} alt="Fille se tenant la queue de cheval" className="grid-2-12 small-grid-1-13" ></img>
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <img src={boohooXsephora2} alt="Fille en tenue décontracé au milieu d'un stade" className="grid-2-12 small-grid-1-13" ></img>
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <img src={boohooXsephora3} alt="Fille dans un ascenceur avec un trench camel" className="grid-2-12 small-grid-1-13" ></img>
                                        </Carousel.Item>
                                    </Carousel>
                                </div>
                        </div>
                    </props.Parallax>

                    <props.Parallax y={['-10px', '10px']}>
                        <div>
                            <div className={'title grid-12 mt-120 mb-120 mobile-space'}></div>
                            {/* ABOVE CONTAINER */}
                            <div className="one-picture">
                                <div className="text text-video">
                                        <p>
                                            {/* BOOHOO  */}
                                            {props.intl.formatMessage({ id: 'projects.scrollContent.title8' })}
                                        </p>
                                        <p>
                                            {/* X ROMANE INNC */}
                                            {props.intl.formatMessage({ id: 'projects.scrollContent.title8.7' })}
                                        </p>
                                        <SeparatorH></SeparatorH>
                                        <div className="badge">{props.intl.formatMessage({ id: 'projects.scrollContent.fashion' })}</div>
                                </div>
                                {/* BELOW CONTAINER */}
                                <div className={'subprojet-container soloImg'}>
                                    <div className={'img-container'}>
                                        <img src={boohooV2} alt="Fille avec un manteau beige" className="grid-2-12 small-grid-1-13 img-100" ></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </props.Parallax>

                    <props.Parallax y={['-10px', '10px']}>
                        <div>
                            <div className={'title grid-12 mt-120 mb-120 project-title margin-title'}>
                                <p className="grid-1-13">
                                    {/* MAC COSMETICS */}
                                    {props.intl.formatMessage({ id: 'projects.scrollContent.title9' })}
                                </p>
                            </div>
                            {/* LEFT CONTAINER */}
                            <div className={'subprojet-container pics-right'}>
                                <div className="text align">
                                        <p>
                                            {/* MAC COSMETICS */}
                                            {props.intl.formatMessage({ id: 'projects.scrollContent.title9' })}
                                        </p>
                                        <p>
                                            {/* X BARBARA MALEWICZ */}
                                            {props.intl.formatMessage({ id: 'projects.scrollContent.title9.1' })}
                                        </p>
                                        <SeparatorH></SeparatorH>
                                        <div className="badge">{props.intl.formatMessage({ id: 'projects.scrollContent.beauty' })}</div>
                                </div>
                                
                                {/* RIGHT CONTAINER */}
                                <div className={'img-container few-pictures'}>
                                    <img src={macXbarbara} alt="Fille se tenant le visage maquillé" className="grid-2-12 small-grid-1-13 img-50" ></img>
                                    <img src={macXbarbara2} alt="Fille maquillé avec boucles d'oreille" className="grid-2-12 small-grid-1-13 img-50" ></img>
                                </div>
                                <Carousel
                                    indicators={false}
                                    keyboard={false}
                                    slide={true}
                                    fade={true}
                                >
                                    <Carousel.Item>
                                        <img src={macXbarbara} alt="Fille se tenant le visage maquillé" className="grid-2-12 small-grid-1-13" ></img>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img src={macXbarbara2} alt="Fille maquillé avec boucles d'oreille" className="grid-2-12 small-grid-1-13" ></img>
                                    </Carousel.Item>
                                </Carousel>
                            </div>
                        </div>
                    </props.Parallax>

                    <props.Parallax y={['-10px', '10px']}>
                        <div>
                            <div className={'title grid-12 mt-120 mb-120 mobile-space'}></div>
                            {/* ABOVE CONTAINER */}
                                <div className="text text-video margin-space">
                                    <div className="text-half">
                                        <p>
                                            {/* MAC COSMETICS */}
                                            {props.intl.formatMessage({ id: 'projects.scrollContent.title9' })}
                                        </p>
                                        <p>
                                            {/* X ANAELLE POSTOLLEC */}
                                            {props.intl.formatMessage({ id: 'projects.scrollContent.title9.2' })}
                                        </p>
                                        <SeparatorH></SeparatorH>
                                        <div className="badge">{props.intl.formatMessage({ id: 'projects.scrollContent.beauty' })}</div>
                                    </div>
                                    {/* BELOW CONTAINER */}
                                    <VideoComponent UrlVideo={vid7} IdVideo="VID_7" ClassNameVideo="video" NotFound={props.intl.formatMessage({ id: 'meta.videonotfound' })}/>
                                </div>
                        </div>
                    </props.Parallax>

                    <props.Parallax y={['-10px', '10px']}>
                        <div>
                            <div className={'title grid-12 mt-120 mb-120 mobile-space'}></div>
                            {/* LEFT CONTAINER */}
                            <div className="two-project_flex">
                                <div className="project-solo_container">
                                    <div className="text align text-video font-size-twoblocks">
                                            <p>
                                                {/* MAC COSMETICS */}
                                                {props.intl.formatMessage({ id: 'projects.scrollContent.title9' })}
                                            </p>
                                            <p>
                                                {/* X LOUISE & MLEESTS */}
                                                {props.intl.formatMessage({ id: 'projects.scrollContent.title9.3' })}
                                            </p>
                                            <SeparatorH></SeparatorH>
                                            <div className="badge">{props.intl.formatMessage({ id: 'projects.scrollContent.beauty' })}</div>
                                    </div>
                                    <div className={'subprojet-container'}>
                                        <div className={'img-container'}>
                                            <img src={macXlouiseXmleests} alt="Selfie de deux filles maquillées en queue de cheval" className="grid-2-12 small-grid-1-13 img-100" ></img>
                                        </div>
                                    </div>
                                </div>
                                <div className="project-solo_container bis">
                                    <div className="text text-video font-size-twoblocks">
                                                <p>
                                                    {/* MAC COSMETICS */}
                                                    {props.intl.formatMessage({ id: 'projects.scrollContent.title9' })}
                                                </p>
                                                <p>
                                                    {/* X VIEW FROM MINA */}
                                                    {props.intl.formatMessage({ id: 'projects.scrollContent.title9.4' })}
                                                </p>
                                                <SeparatorH></SeparatorH>
                                                <div className="badge">{props.intl.formatMessage({ id: 'projects.scrollContent.beauty' })}</div>
                                        </div>
                                    <div className={'subprojet-container'}>
                                        <div className={'img-container'}>
                                            <img src={macXmina} alt="Selfie d'une fille maquillé avec une épaule dénudée" className="grid-2-12 small-grid-1-13 img-100" ></img>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </props.Parallax>

                    <props.Parallax y={['-10px', '10px']}>
                        <div>
                            <div className={'title grid-12 mt-120 mb-120 mobile-space'}></div>
                            {/* ABOVE CONTAINER */}
                                <div className="text text-video text-right-vid">
                                    <p>
                                        {/* MAC COSMETICS */}
                                        {props.intl.formatMessage({ id: 'projects.scrollContent.title9' })}
                                    </p>
                                    <p>
                                        {/* X MIMIARR */}
                                        {props.intl.formatMessage({ id: 'projects.scrollContent.title9.5' })}
                                    </p>
                                    <SeparatorH></SeparatorH>
                                    <div className="badge">{props.intl.formatMessage({ id: 'projects.scrollContent.beauty' })}</div>
                                    {/* BELOW CONTAINER */}
                                </div>
                                <div className="video-right">
                                    <VideoComponent UrlVideo={vid8} IdVideo="VID_8" ClassNameVideo="video" NotFound={props.intl.formatMessage({ id: 'meta.videonotfound' })}/>
                                </div>
                        </div>
                    </props.Parallax>

                    <props.Parallax y={['-10px', '10px']}>
                        <div>
                            <div className={'title grid-12 mt-120 mb-120 project-title margin-title'}>
                                <p className="grid-1-13">
                                    {/* JUSTFAB */}
                                    {props.intl.formatMessage({ id: 'projects.scrollContent.title10' })}
                                </p>
                            </div>
                            {/* LEFT CONTAINER */}
                            <div className={'subprojet-container reverse'}>
                                <div className={'img-container few-pictures'}>
                                    <img src={justfabXsephora} alt="Fille accroupie avec des lunettes de soleil et un sac dans les mains" className="grid-2-12 small-grid-1-13 img-50" ></img>
                                    <img src={justfabXsephora2} alt="Fille assise sur une chaise portant une chemise longue à carreaux" className="grid-2-12 small-grid-1-13 img-50" ></img>
                                </div>
                                <Carousel
                                    indicators={false}
                                    keyboard={false}
                                    slide={true}
                                    fade={true}
                                >
                                    <Carousel.Item>
                                        <img src={justfabXsephora} alt="Fille accroupie avec des lunettes de soleil et un sac dans les mains" className="grid-2-12 small-grid-1-13" ></img>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img src={justfabXsephora2} alt="Fille assise sur une chaise portant une chemise longue à carreaux" className="grid-2-12 small-grid-1-13" ></img>
                                    </Carousel.Item>
                                </Carousel>
                                {/* RIGHT CONTAINER */}
                                <div className="text">
                                    <p>
                                        {/* JUSTFAB */}
                                        {props.intl.formatMessage({ id: 'projects.scrollContent.title10' })}
                                    </p>
                                    <p>
                                        {/* X SEPHORA KNG */}
                                        {props.intl.formatMessage({ id: 'projects.scrollContent.title10.1' })}
                                    </p>
                                    <SeparatorH></SeparatorH>
                                    <div className="badge">{props.intl.formatMessage({ id: 'projects.scrollContent.fashion' })}</div>
                                </div>
                            </div>
                        </div>
                    </props.Parallax>

                    <props.Parallax y={['-10px', '10px']}>
                        <div>
                            <div className={'title grid-12 mt-120 mb-120 mobile-space'}></div>
                            {/* ABOVE CONTAINER */}
                            <div className="text align">
                                <p>
                                    {/* JUSTFAB */}
                                    {props.intl.formatMessage({ id: 'projects.scrollContent.title10' })}
                                </p>
                                <p>
                                    {/* X MIMIARR */}
                                    {props.intl.formatMessage({ id: 'projects.scrollContent.title10.2' })}
                                </p>
                                <SeparatorH></SeparatorH>
                                <div className="badge">{props.intl.formatMessage({ id: 'projects.scrollContent.fashion' })}</div>
                            </div>
                            <div className={'subprojet-container'}>                                
                                {/* BELOW CONTAINER */}
                                <div className={'img-container pic3 few-pictures'}>
                                    <img src={justfabXmimiarr} alt="Fille en jupe avec des talons qui regarde le sol" className="grid-2-12 small-grid-1-13 img-33" ></img>
                                    <img src={justfabXmimiarr2} alt="Fille assise devant un miroir" className="grid-2-12 small-grid-1-13 img-33" ></img>
                                    <img src={justfabXmimiarr3} alt="Fille avec des cuissardes et un manteau long dans les rues de Paris" className="grid-2-12 small-grid-1-13 img-33" ></img>
                                </div>
                                <Carousel
                                    indicators={false}
                                    keyboard={false}
                                    slide={true}
                                    fade={true}
                                >
                                    <Carousel.Item>
                                        <img src={justfabXmimiarr} alt="Fille en jupe avec des talons qui regarde le sol" className="grid-2-12 small-grid-1-13" ></img>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img src={justfabXmimiarr2} alt="Fille assise devant un miroir" className="grid-2-12 small-grid-1-13" ></img>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img src={justfabXmimiarr3} alt="Fille avec des cuissardes et un manteau long dans les rues de Paris" className="grid-2-12 small-grid-1-13" ></img>
                                    </Carousel.Item>
                                </Carousel>
                            </div>
                        </div>
                    </props.Parallax>

                    <props.Parallax y={['-10px', '10px']}>
                        <div>
                            <div className={'title grid-12 mt-120 mb-120 mobile-space'}></div>
                            {/* LEFT CONTAINER */}
                            <div className={'subprojet-container pics-right'}>
                                <div className="text align">
                                        <p>
                                            {/* JUSTFAB */}
                                            {props.intl.formatMessage({ id: 'projects.scrollContent.title10' })}
                                        </p>
                                        <p>
                                            {/* X AUDREY AZZAR */}
                                            {props.intl.formatMessage({ id: 'projects.scrollContent.title10.3' })}
                                        </p>
                                        <SeparatorH></SeparatorH>
                                        <div className="badge">{props.intl.formatMessage({ id: 'projects.scrollContent.fashion' })}</div>
                                </div>
                                
                                {/* RIGHT CONTAINER */}
                                <div className={'img-container few-pictures'}>
                                    <img src={justfabXaudrey} alt="Fille devant un miroir avec un bonnet, écharpe et manteau" className="grid-2-12 small-grid-1-13 img-50" ></img>
                                    <img src={justfabXaudrey2} alt="Fille devant une porte d'entrée tenant son sac à deux mains" className="grid-2-12 small-grid-1-13 img-50" ></img>
                                </div>
                                <Carousel
                                    indicators={false}
                                    keyboard={false}
                                    slide={true}
                                    fade={true}
                                >
                                    <Carousel.Item>
                                        <img src={justfabXaudrey} alt="Fille devant un miroir avec un bonnet, écharpe et manteau" className="grid-2-12 small-grid-1-13" ></img>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img src={justfabXaudrey2} alt="Fille devant une porte d'entrée tenant son sac à deux mains" className="grid-2-12 small-grid-1-13" ></img>
                                    </Carousel.Item>
                                </Carousel>
                            </div>
                        </div>
                    </props.Parallax>

                    <props.Parallax y={['-10px', '10px']}>
                        <div>
                            <div className={'title grid-12 mt-120 mb-120 mobile-space'}></div>
                            {/* ABOVE CONTAINER */}
                            <div className="text align">
                                <p>
                                    {/* JUSTFAB */}
                                    {props.intl.formatMessage({ id: 'projects.scrollContent.title10' })}
                                </p>
                                <p>
                                    {/* X MIMIARR */}
                                    {props.intl.formatMessage({ id: 'projects.scrollContent.title10.4' })}
                                </p>
                                <SeparatorH></SeparatorH>
                                <div className="badge">{props.intl.formatMessage({ id: 'projects.scrollContent.fashion' })}</div>
                            </div>
                            <div className={'subprojet-container'}>                                
                                {/* BELOW CONTAINER */}
                                <div className={'img-container pic3 few-pictures'}>
                                    <img src={justfab1V2} alt="Fille en jupe avec des talons qui regarde le sol" className="grid-2-12 small-grid-1-13 img-33" ></img>
                                    <img src={justfab2V2} alt="Fille assise devant un miroir" className="grid-2-12 small-grid-1-13 img-33" ></img>
                                    <img src={justfab3V2} alt="Fille avec des cuissardes et un manteau long dans les rues de Paris" className="grid-2-12 small-grid-1-13 img-33" ></img>
                                </div>
                                <Carousel
                                    indicators={false}
                                    keyboard={false}
                                    slide={true}
                                    fade={true}
                                >
                                    <Carousel.Item>
                                        <img src={justfab1V2} alt="Fille en jupe avec des talons qui regarde le sol" className="grid-2-12 small-grid-1-13" ></img>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img src={justfab2V2} alt="Fille assise devant un miroir" className="grid-2-12 small-grid-1-13" ></img>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img src={justfab3V2} alt="Fille avec des cuissardes et un manteau long dans les rues de Paris" className="grid-2-12 small-grid-1-13" ></img>
                                    </Carousel.Item>
                                </Carousel>
                            </div>
                        </div>
                    </props.Parallax>

                    <props.Parallax y={['-10px', '10px']}>
                        <div>
                            <div className={'title grid-12 mt-120 mb-120 project-title'}>
                                <p className="grid-1-13">
                                    {/* KIKO MILANO */}
                                    {props.intl.formatMessage({ id: 'projects.scrollContent.title11' })}
                                </p>
                            </div>
                            {/* RIGHT CONTAINER */}
                                <div className={'subprojet-container float-left-pic reverse'}>
                                    <VideoComponent UrlVideo={vid9} IdVideo="VID_9" ClassNameVideo="video video-left" NotFound={props.intl.formatMessage({ id: 'meta.videonotfound' })}/>
                                    <div className="text reverse">
                                    {/* LEFT CONTAINER */}
                                        <p>
                                            {/* KIKO MILANO */}
                                            {props.intl.formatMessage({ id: 'projects.scrollContent.title11' })}
                                        </p>
                                        <p>
                                            {/* X HONEY SHAY */}
                                            {props.intl.formatMessage({ id: 'projects.scrollContent.title11.1' })}
                                        </p>
                                        <SeparatorH></SeparatorH>
                                        <div className="badge">{props.intl.formatMessage({ id: 'projects.scrollContent.beauty' })}</div>
                                    </div>
                                </div>
                        </div>
                    </props.Parallax>

                    <props.Parallax y={['-10px', '10px']}>
                        <div>
                            <div className={'title grid-12 mt-120 mb-120 project-title'}>
                                <p className="grid-1-13">
                                    {/* LOUNGE UNDERWEAR */}
                                    {props.intl.formatMessage({ id: 'projects.scrollContent.title12' })}
                                </p>
                            </div>
                            {/* ABOVE CONTAINER */}
                                <div className="text align">
                                    <p>
                                        {/* LOUNGE UNDERWEAR */}
                                        {props.intl.formatMessage({ id: 'projects.scrollContent.title12' })}
                                    </p>
                                    <p>
                                        {/* X GAELLE GD */}
                                        {props.intl.formatMessage({ id: 'projects.scrollContent.title12.1' })}
                                    </p>
                                    <SeparatorH></SeparatorH>
                                    <div className="badge">{props.intl.formatMessage({ id: 'projects.scrollContent.fashion' })}</div>
                                </div>
                                <div className={'subprojet-container'}>                                
                                    {/* BELOW CONTAINER */}
                                    <div className={'img-container pic3 few-pictures'}>
                                        <img src={loungeunderwearXgaelle} alt="Fille en sous-vêtement blanc avec un pull tricot" className="grid-2-12 small-grid-1-13 img-33" ></img>
                                        <img src={loungeunderwearXgaelle2} alt="Fille en sous-vêtement vert avec un pull blanc" className="grid-2-12 small-grid-1-13 img-33" ></img>
                                        <img src={loungeunderwearXgaelle3} alt="Fille en sous-vêtement alllongée sur un canapé" className="grid-2-12 small-grid-1-13 img-33" ></img>
                                    </div>
                                    <Carousel
                                        indicators={false}
                                        keyboard={false}
                                        slide={true}
                                        fade={true}
                                    >
                                        <Carousel.Item>
                                            <img src={loungeunderwearXgaelle} alt="Fille en sous-vêtement blanc avec un pull tricot" className="grid-2-12 small-grid-1-13" ></img>
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <img src={loungeunderwearXgaelle2} alt="Fille en sous-vêtement vert avec un pull blanc" className="grid-2-12 small-grid-1-13" ></img>
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <img src={loungeunderwearXgaelle3} alt="Fille en sous-vêtement alllongée sur un canapé" className="grid-2-12 small-grid-1-13" ></img>
                                        </Carousel.Item>
                                    </Carousel>
                                </div>
                        </div>
                    </props.Parallax>

                    <props.Parallax y={['-10px', '10px']}>
                        <div>
                            <div className={'title grid-12 mt-120 mb-120 mobile-space'}></div>
                            {/* LEFT CONTAINER */}
                            <div className={'subprojet-container reverse'}>
                                <div className={'img-container few-pictures'}>
                                    <img src={loungeunderwearXoce} alt="Fille en débardeur et culotte de couleur gris" className="grid-2-12 small-grid-1-13 img-50" ></img>
                                    <img src={loungeunderwearXoce2} alt="Fille dans un salon en débardeur et culotte" className="grid-2-12 small-grid-1-13 img-50" ></img>
                                </div>
                                <Carousel
                                    indicators={false}
                                    keyboard={false}
                                    slide={true}
                                    fade={true}
                                >
                                    <Carousel.Item>
                                        <img src={loungeunderwearXoce} alt="Fille en débardeur et culotte de couleur gris" className="grid-2-12 small-grid-1-13" ></img>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img src={loungeunderwearXoce2} alt="Fille dans un salon en débardeur et culotte" className="grid-2-12 small-grid-1-13" ></img>
                                    </Carousel.Item>
                                </Carousel>
                                {/* RIGHT CONTAINER */}
                                <div className="text">
                                        <p>
                                            {/* LOUNGE UNDERWEAR */}
                                            {props.intl.formatMessage({ id: 'projects.scrollContent.title12' })}
                                        </p>
                                        <p>
                                            {/* X OCE.RTHN*/}
                                            {props.intl.formatMessage({ id: 'projects.scrollContent.title12.2' })}
                                        </p>
                                        <SeparatorH></SeparatorH>
                                        <div className="badge">{props.intl.formatMessage({ id: 'projects.scrollContent.fashion' })}</div>
                                </div>
                            </div>
                        </div>
                    </props.Parallax>

                    <props.Parallax y={['-10px', '10px']}>
                        <div>
                            <div className={'title grid-12 mt-120 mb-120 mobile-space'}></div>
                            {/* ABOVE CONTAINER */}
                                <div className="text align">
                                    <p>
                                        {/* LOUNGE UNDERWEAR */}
                                        {props.intl.formatMessage({ id: 'projects.scrollContent.title12' })}
                                    </p>
                                    <p>
                                        {/* X LOUISE RGT*/}
                                        {props.intl.formatMessage({ id: 'projects.scrollContent.title12.3' })}
                                    </p>
                                    <SeparatorH></SeparatorH>
                                    <div className="badge">{props.intl.formatMessage({ id: 'projects.scrollContent.fashion' })}</div>
                                </div>
                                <div className={'subprojet-container'}>                                
                                    {/* BELOW CONTAINER */}
                                    <div className={'img-container pic3 few-pictures'}>
                                        <img src={loungeunderwearXlouise} alt="Fille en sous-vêtement devant le miroir" className="grid-2-12 small-grid-1-13 img-33" ></img>
                                        <img src={loungeunderwearXlouise2} alt="Fille en jean, soutient-gorge" className="grid-2-12 small-grid-1-13 img-33" ></img>
                                        <img src={loungeunderwearXlouise3} alt="Fille en maillot de bain deux pièces" className="grid-2-12 small-grid-1-13 img-33" ></img>
                                    </div>
                                    <Carousel
                                        indicators={false}
                                        keyboard={false}
                                        slide={true}
                                        fade={true}
                                    >
                                        <Carousel.Item>
                                            <img src={loungeunderwearXlouise} alt="Fille en sous-vêtement devant le miroir" className="grid-2-12 small-grid-1-13" ></img>
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <img src={loungeunderwearXlouise2} alt="Fille en jean, soutient-gorge" className="grid-2-12 small-grid-1-13" ></img>
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <img src={loungeunderwearXlouise3} alt="Fille en maillot de bain deux pièces" className="grid-2-12 small-grid-1-13" ></img>
                                        </Carousel.Item>
                                    </Carousel>
                                </div>
                        </div>
                    </props.Parallax>

                    <props.Parallax y={['-10px', '10px']}>
                        <div>
                            <div className={'title grid-12 mt-120 mb-120 mobile-space'}></div>
                            {/* ABOVE CONTAINER */}
                            <div className="one-picture">
                                <div className="text text-video">
                                        <p>
                                            {/* Lounge v2  */}
                                            {props.intl.formatMessage({ id: 'projects.scrollContent.title12' })}
                                        </p>
                                        <p>
                                            {/* Lounge v2 */}
                                            {props.intl.formatMessage({ id: 'projects.scrollContent.title12.4' })}
                                        </p>
                                        <SeparatorH></SeparatorH>
                                        <div className="badge">{props.intl.formatMessage({ id: 'projects.scrollContent.fashion' })}</div>
                                </div>
                                {/* BELOW CONTAINER */}
                                <div className={'subprojet-container soloImg'}>
                                    <div className={'img-container'}>
                                        <img src={loungeunderwearV2} alt="Fille avec un manteau beige" className="grid-2-12 small-grid-1-13 img-100" ></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </props.Parallax>


                    <props.Parallax y={['-10px', '10px']}>
                        <div>
                            <div className={'title grid-12 mt-120 mb-120 project-title margin-title'}>
                                <p className="grid-1-13">
                                    {/* METROPOLITAN FILMS */}
                                    {props.intl.formatMessage({ id: 'projects.scrollContent.title13' })}
                                </p>
                            </div>
                            {/* LEFT CONTAINER */}
                            <div className={'subprojet-container reverse float-left-pic'}>
                                <div className={'img-container'}>
                                    <img src={metropolitanXfahdel} alt="Homme effrayé par un enfant ventriloque" className="grid-2-12 small-grid-1-13 img-100" ></img>
                                </div>
                                {/* RIGHT CONTAINER */}
                                <div className="text">
                                        <p>
                                            {/* BRAHMS: THE BOY II */}
                                            {props.intl.formatMessage({ id: 'projects.scrollContent.title13.1' })}
                                        </p>
                                        <p>
                                            {/* X FAHDEL */}
                                            {props.intl.formatMessage({ id: 'projects.scrollContent.title13.2' })}
                                        </p>
                                        <SeparatorH></SeparatorH>
                                        <div className="badge">{props.intl.formatMessage({ id: 'projects.scrollContent.lifestyle' })}</div>
                                </div>
                                
                            </div>
                        </div>
                    </props.Parallax>

                    <props.Parallax y={['-10px', '10px']}>
                        <div>
                            <div className={'title grid-12 mt-120 mb-120 project-title'}>
                                <p className="grid-1-13">
                                    {/* PAUL HEWITT */}
                                    {props.intl.formatMessage({ id: 'projects.scrollContent.title14' })}
                                </p>
                            </div>
                            {/* LEFT CONTAINER */}
                            <div className={'subprojet-container pics-right'}>
                                <div className="text align">
                                        <p>
                                            {/* PAUL HEWITT */}
                                            {props.intl.formatMessage({ id: 'projects.scrollContent.title14' })}
                                        </p>
                                        <p>
                                            {/* X MIMIARR */}
                                            {props.intl.formatMessage({ id: 'projects.scrollContent.title14.1' })}
                                        </p>
                                        <SeparatorH></SeparatorH>
                                        <div className="badge">{props.intl.formatMessage({ id: 'projects.scrollContent.fashion' })}</div>
                                </div>
                                
                                {/* RIGHT CONTAINER */}
                                <div className={'img-container few-pictures'}>
                                    <img src={paulhewittXmimiarr} alt="Poignet avec une montre et bracelet en or" className="grid-2-12 small-grid-1-13 img-50" ></img>
                                    <img src={paulhewittXmimiarr2} alt="Fille avec un manteau et un sac" className="grid-2-12 small-grid-1-13 img-50" ></img>
                                </div>
                                <Carousel
                                    indicators={false}
                                    keyboard={false}
                                    slide={true}
                                    fade={true}
                                >
                                    <Carousel.Item>
                                        <img src={paulhewittXmimiarr} alt="Poignet avec une montre et bracelet en or" className="grid-2-12 small-grid-1-13" ></img>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img src={paulhewittXmimiarr2} alt="Fille avec un manteau et un sac" className="grid-2-12 small-grid-1-13" ></img>
                                    </Carousel.Item>
                                </Carousel>
                            </div>
                        </div>
                    </props.Parallax>

                    <props.Parallax y={['-10px', '10px']}>
                        <div>
                            <div className={'title grid-12 mt-120 mb-120 project-title'}>
                                <p className="grid-1-13">
                                    {/* NA-KD */}
                                    {props.intl.formatMessage({ id: 'projects.scrollContent.title15' })}
                                </p>
                            </div>
                            {/* ABOVE CONTAINER */}
                                <div className="text align">
                                    <p>
                                        {/* NA-KD */}
                                        {props.intl.formatMessage({ id: 'projects.scrollContent.title15' })}
                                    </p>
                                    <p>
                                        {/* X GAELLE GD */}
                                        {props.intl.formatMessage({ id: 'projects.scrollContent.title15.1' })}
                                    </p>
                                    <SeparatorH></SeparatorH>
                                    <div className="badge">{props.intl.formatMessage({ id: 'projects.scrollContent.fashion' })}</div>
                                </div>
                                <div className={'subprojet-container'}>                                
                                    {/* BELOW CONTAINER */}
                                    <div className={'img-container pic3 few-pictures'}>
                                        <img src={nakdXgaelle} alt="Fille près d'un buisson avec un manteau kaki et des lunettes de soleil" className="grid-2-12 small-grid-1-13 img-33" ></img>
                                        <img src={nakdXgaelle2} alt="Fille qui retire ces lunettes de soleil tout en portant son sac à main" className="grid-2-12 small-grid-1-13 img-33" ></img>
                                        <img src={nakdXgaelle3} alt="Fille qui marche dans un parc" className="grid-2-12 small-grid-1-13 img-33" ></img>
                                    </div>
                                    <Carousel
                                        indicators={false}
                                        keyboard={false}
                                        slide={true}
                                        fade={true}
                                    >
                                        <Carousel.Item>
                                            <img src={nakdXgaelle} alt="Fille près d'un buisson avec un manteau kaki et des lunettes de soleil" className="grid-2-12 small-grid-1-13" ></img>
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <img src={nakdXgaelle2} alt="Fille qui retire ces lunettes de soleil tout en portant son sac à main" className="grid-2-12 small-grid-1-13" ></img>
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <img src={nakdXgaelle3} alt="Fille qui marche dans un parc" className="grid-2-12 small-grid-1-13" ></img>
                                        </Carousel.Item>
                                    </Carousel>
                                </div>
                        </div>
                    </props.Parallax>

                    <props.Parallax y={['-50px', '50px']}>
                        <div>
                            <div className={'title grid-12 mt-120 mb-120 project-title margin-title'}>
                                <p className="grid-1-13">
                                    {/* REVOLVE */}
                                    {props.intl.formatMessage({ id: 'projects.scrollContent.title16' })}
                                </p>
                            </div>
                            {/* ABOVE CONTAINER */}
                            <div className="one-picture">
                                <div className="text text-video">
                                        <p>
                                            {/* REVOLVE */}
                                            {props.intl.formatMessage({ id: 'projects.scrollContent.title16' })}
                                        </p>
                                        <p>
                                            {/* X LADY NELA */}
                                            {props.intl.formatMessage({ id: 'projects.scrollContent.title16.1' })}
                                        </p>
                                        <SeparatorH></SeparatorH>
                                        <div className="badge">{props.intl.formatMessage({ id: 'projects.scrollContent.fashion' })}</div>
                                    {/* BELOW CONTAINER */}
                                </div>
                                <div className={'subprojet-container soloImg'}>
                                    <div className={'img-container'}>
                                        <img src={revolveXlady} alt="Fille allongée sur un canapé près de son sac à main" className="grid-2-12 small-grid-1-13 img-100 last-img" ></img>
                                    </div>
                                </div>
                            </div>                      
                        </div>
                    </props.Parallax>

                    <SeparatorH></SeparatorH>
                    
                    <div className={projectsSectionStyles.subtitle + ' title title-empty grid-12'}>
                        <p className="grid-3-11">
                            {/* ON RESTE EN CONTACT */}
                            {props.intl.formatMessage({ id: 'main.scrollContent.title5' })}
                        </p>
                    </div>
                    <ContactForm intl={props.intl}></ContactForm>
                </div>
            </props.ParallaxProvider>
        )
    }
}