import React from 'react';

import avamay from '../assets/images/projects/logos/ava_may.png';
import boohoo from '../assets/images/projects/logos/boohoo.png';
import cheerz from '../assets/images/projects/logos/cheerz.png';
import claires from '../assets/images/projects/logos/clairs.png';
import cluse from '../assets/images/projects/logos/cluse.png';
import deezer from '../assets/images/projects/logos/deezer.png';
import foreo from '../assets/images/projects/logos/foreo.png';
import hellofreshlockup from '../assets/images/projects/logos/hello_fresh.png';
import houseofcb from '../assets/images/projects/logos/house_of_cb.png';
import jbl from '../assets/images/projects/logos/jbl.png';
import justfab from '../assets/images/projects/logos/just_fab.png';
import kaptenandson from '../assets/images/projects/logos/kapten_&_son.png';
import kikomilano from '../assets/images/projects/logos/kiko_milano.png';
import laredoute from '../assets/images/projects/logos/la_redoute.png';
import loungeunderwear from '../assets/images/projects/logos/lounge.png';
import mac from '../assets/images/projects/logos/mac.png';
import missguided from '../assets/images/projects/logos/missguided.png';
import nakd from '../assets/images/projects/logos/na_kd.png';
import nastygal from '../assets/images/projects/logos/nasty_gal.png';
import nuin from '../assets/images/projects/logos/nu_in.png';
import paulhewitt from '../assets/images/projects/logos/paul_hewitt.png';
import pomeloco from '../assets/images/projects/logos/pomelo_co.png';
import purelei from '../assets/images/projects/logos/purelei.png';
import revolve from '../assets/images/projects/logos/revolve.png';
import savage from '../assets/images/projects/logos/savage.png';
import toofaced from '../assets/images/projects/logos/too_faced.png';
import vinted from '../assets/images/projects/logos/vinted.png';
import hello_body from '../assets/images/projects/logos/hello_body.png';
import back_market from '../assets/images/projects/logos/back_market.png';
import petit_cube from '../assets/images/projects/logos/petit_cube.png';

export default class ProjectsSectionLogo extends React.Component {
    render() {
        return (
            <div className="logo-container">
                <img className="logo-container_icon" src={avamay} alt="brandname avamay"></img>
                <img className="logo-container_icon" src={boohoo} alt="brandname boohoo"></img>
                <img className="logo-container_icon" src={cheerz} alt="brandname cheerz"></img>
                <img className="logo-container_icon" src={claires} alt="brandname claires"></img>
                <img className="logo-container_icon" src={cluse} alt="brandname cluse"></img>
                <img className="logo-container_icon" src={deezer} alt="brandname deezer"></img>
                <img className="logo-container_icon" src={foreo} alt="brandname foreo"></img>
                <img className="logo-container_icon" src={hellofreshlockup} alt="brandname hellofreshlockup"></img>
                <img className="logo-container_icon" src={houseofcb} alt="brandname houseofcb"></img>
                <img className="logo-container_icon" src={jbl} alt="brandname jbl"></img>
                <img className="logo-container_icon" src={justfab} alt="brandname justfab"></img>
                <img className="logo-container_icon" src={kaptenandson} alt="brandname kaptenandson"></img>
                <img className="logo-container_icon" src={kikomilano} alt="brandname kikomilano"></img>
                <img className="logo-container_icon" src={laredoute} alt="brandname laredoute"></img>
                <img className="logo-container_icon" src={loungeunderwear} alt="brandname loungeunderwear"></img>
                <img className="logo-container_icon" src={mac} alt="brandname m.a.c"></img>
                <img className="logo-container_icon" src={missguided} alt="brandname missguided"></img>
                <img className="logo-container_icon" src={nakd} alt="brandname nakd"></img>
                <img className="logo-container_icon" src={nastygal} alt="brandname nastygal"></img>
                <img className="logo-container_icon" src={nuin} alt="brandname nuin"></img>
                <img className="logo-container_icon" src={paulhewitt} alt="brandname paulhewitt"></img>
                <img className="logo-container_icon" src={pomeloco} alt="brandname pomeloco"></img>
                <img className="logo-container_icon" src={purelei} alt="brandname purelei"></img>
                <img className="logo-container_icon" src={revolve} alt="brandname revolve"></img>
                <img className="logo-container_icon" src={savage} alt="brandname savage"></img>
                <img className="logo-container_icon" src={toofaced} alt="brandname toofaced"></img>
                <img className="logo-container_icon" src={vinted} alt="brandname vinted"></img>
                <img className="logo-container_icon" src={hello_body} alt="hello_body"></img>
                <img className="logo-container_icon" src={back_market} alt="back_market"></img>
                <img className="logo-container_icon" src={petit_cube} alt="petit_cube"></img>
            </div>
        )
    };
}